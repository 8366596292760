import axios from 'axios'
import router from '../router'
import store from '../store'
import { Toast } from 'vant'
import Vue from 'vue'

const url = window.location.href.slice(0,window.location.href.indexOf('/#'))
localStorage.setItem('url',url)
const url2 = window.location.href.slice(0,window.location.href.indexOf('?code'))
localStorage.setItem('url2',url2)
if(url == 'https://td-h5.1des1.com.cn'){
	axios.defaults.baseURL = 'https://td.1des1.com.cn'
	Vue.prototype.baseUrl2 = 'https://h5.1des1.com.cn'
	localStorage.setItem('share','https://h5.1des1.com.cn')
}else{
	axios.defaults.baseURL = 'https://tdtest.1des1.com.cn'
	Vue.prototype.baseUrl2 = 'https://test.1des1.com.cn'
	localStorage.setItem('share','https://test.1des1.com.cn')
}
// alert('分享url'+localStorage.getItem('share'))
// 请求拦截
axios.interceptors.request.use(config => {
	// 自定义header，可添加项目token
	if(config.data == undefined){
		config.data = {}
	}
	const accessToken = localStorage.getItem('accessToken')
	const scsjStoreId = localStorage.getItem('scsjStoreId')
	config.data.token = accessToken
	if(config.data.store_id == 0){
		Reflect.deleteProperty(config.data,'store_id')
	}else{
		config.data.store_id = scsjStoreId
	}
	// config.headers.token = 'token';
	return config;
});
// 返回拦截
axios.interceptors.response.use((response)=>{
	// 获取接口返回结果
	const res = response;
	// code为0，直接把结果返回回去，这样前端代码就不用在获取一次data.
	if(res.data?.code === 1){
			return res;
	}else if(res.data?.code == 401){
			// 10000假设是未登录状态码
			localStorage.removeItem('accessToken')
			// 也可使用router进行跳转
			router.push({path:'/login'})
			Toast.fail('身份验证过期');
			return res;
	}else{
			// 错误显示可在service中控制，因为某些场景我们不想要展示错误
			// Message.error(res.message);
			return res;
	}
},({response})=>{
	if(response.data?.code === 401){
		localStorage.removeItem('accessToken')
		Toast.fail(response.data?.msg)
		// 也可使用router进行跳转
		router.push({path:'/login'})
		return response;
	}else{
		Toast.fail(response.data?.msg);
	}
});

export default axios