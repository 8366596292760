<template>
	<div id="app">
		<router-view/>
		<GlobalWindow></GlobalWindow>
	</div>
</template>

<script>
import GlobalWindow from '@/components/globalwindow.vue'
import {getsignature} from '@/utils/utils'
export default {
	components: {
		GlobalWindow
	},
	data () {
		return {

		}
	},
	mounted () {
		console.log('wx',this.$wx)
		this.$store.commit('setheight', document.documentElement.clientHeight)
		this.getWindowOpen()
		const url = window.location.href.slice(0,window.location.href.indexOf('#/'))
		// alert(url)
		if(url){
			getsignature(url)
		}
		setInterval(() => {
			this.getWindowOpen()
		}, 60000)
	},
	methods: {
		async getWindowOpen(e) {
			const accessToken = localStorage.getItem('accessToken')
			if (accessToken == null || accessToken == '') {
				return
			}
			const {data: result} = await this.$http.post('/api/scsj/get_message_window_1_5', {
				store_id: 0
			})
			if (result.code == 1 && result.data.status == 101) {
				console.log(result)
				this.$store.commit('setGlobalwindow', result.data.res[0])
			}
		},
	}
}
</script>

<style lang="scss">
*{
	padding: 0;
	margin: 0;
}

.picker-div {
	width: 100%;
	height: 300px;
	background-color: #575757 !important;
	font-family: HYQiHeiY3-45;
	.van-picker__mask{
		background-image:none !important;
	}
	.van-ellipsis{
			color: #ffffff !important;
		}
}

.custom-image {
	padding: 50px 0;
	display: flex;
	height: 600px;
	justify-content: center;
	flex-direction: column;
	font-family:HYQiHeiY3-55;
	align-items: center;
	div{
		margin-top: 20px;
		color: #ECECEC;
		opacity: 0.5;
	}
	img{
		width: 140px;
		height: 90px;
	}

 }

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}

.rotate-circle{
	display: none;
}
.tmap-zoom-control{
	display: none !important;
}
.tmap-scale-line{
	display: none !important;
}
.tmap-scale-text{
	display: none !important;
}
.logo-text{
	display: none !important;
}

#app {
	color: #2c3e50;
	background-color: #191919;
	height: 100%;
}
body,html{
	background-color: #191919;
	height: 100%;
}

.placeholderinput{
		font-family: HYQiHeiY3-45;
	}


// 导航栏样式透明

.nav_bar_center{
	background-color: #191919 !important;
	color: #ffffff !important;
	font-family: HYQiHeiY3-45;
	.van-nav-bar__title{
	color: #ffffff !important;
	font-family: HYQiHeiY3-45;
	}
	.van-nav-bar__text{
		color: #ffffff;
		font-family: HYQiHeiY3-45;
	}
	.van-nav-bar{
		background-color: rgba(25, 25, 25, 0) !important;
		.van-icon {
		color: #ffffff;
	}
	} 

}

// 导航栏样式不透明

.nav_bar_color{
	font-family: HYQiHeiY3-45;
	background-color: #191919 !important;
	color: #ffffff !important;
	.van-nav-bar__title{
	color: #ffffff !important;
	}
	.van-nav-bar__text{
		color: #ffffff;
	}
	.van-nav-bar .van-icon {
		color: #ffffff;
	}
	.van-nav-bar{
		box-sizing: border-box;
		background-color: #191919;
	} 

}
</style>
