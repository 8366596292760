<template>
	<div class="globalwindow alertDialogPage">
		<van-popup  v-model="globalWindowPopup" :style="{'backgroundColor': '#ECECEC'}" :close-on-click-overlay="false" position="center">
			<div class="globalwindow__box">
				<div class="globalwindow__box__bgleft"></div>
				<div class="globalwindow__box__bgright"></div>
				
				<!-- 协同工作弹窗 -->
				<div class="xtgz" v-if="globalwindowInfo.typelist == 1">
					<div class="xtgz__avatar"><img :src="globalwindowInfo.send_user_avatar" mode="aspectFill" /></div>
					<div class="xtgz__name">{{globalwindowInfo.send_user_name}}</div>
					<div class="xtgz__init">邀请您一起协助完成本次试乘试驾</div>
					<div class="xtgz__car">试驾车型：{{globalwindowInfo.car_model_name}}</div>
					<div class="xtgz__btns">
						<div @click="handConfirm(0)">谢绝邀请</div>
						<div @click="handConfirm(1)">接受邀请</div>
					</div>
				</div>
				
				<!-- 协同工作弹窗 -->
				<div class="xtgzok" v-if="globalwindowInfo.typelist == 4">
					<div class="xtgzok__close" @click="handClose">
						关闭<img src="@/assets/globalwindow/icon_close.png" />
					</div>
					<div class="xtgzok__logo"><img src="@/assets/globalwindow/icon_succ1.png" /></div>
					<div class="xtgzok__title">已接受邀请</div>
					<div class="xtgzok__btn" @click="handInfo">查看详情</div>
				</div>
				
				<!-- 确认路线弹窗 -->
				<div class="qrtc" v-if="globalwindowInfo.typelist == 2">
					<div class="qrtc__logo"><img src="@/assets/globalwindow/icon_succ1.png" /></div>
					<div class="qrtc__title">客户已完成以下信息填报</div>
					<div class="qrtc__init">
						<div>试驾线路选择</div>
						<img src="@/assets/globalwindow/icon_succ2.png" />
					</div>
					<div class="qrtc__btn" @click="handCheck">确定</div>
				</div>
				
				<!-- 确认路线弹窗 -->
				<div class="qrtc" v-if="globalwindowInfo.typelist == 3">
					<div class="qrtc__logo"><img src="@/assets/globalwindow/icon_succ1.png" /></div>
					<div class="qrtc__title">客户已完成以下信息填报</div>
					<div class="qrtc__init">
						<div>试乘试驾反馈</div>
						<img src="@/assets/globalwindow/icon_succ2.png" />
					</div>
					<div class="qrtc__btn" @click="handReport">查看详情</div>
				</div>
			</div>
		</van-popup>
		
		<!-- 弹出框 -->
		<van-dialog v-model="alertDialogShow" @confirm="handConfirmReq" :title="alertDialogTitle" show-cancel-button>
			<div class="invite__init">
				{{alertDialogContent}}
			</div>
		</van-dialog>
	</div>
</template>

<script>
export default {
	name: 'GlobalWindow',
	data() {
		return {
			globalwindowInfo: {},
			
			globalwindowIdNow: 0,
			alertDialogTitle: '',
			alertDialogContent: '',
			intention: 1,
			globalWindowPopup: false,
			alertDialogShow: false,
		};
	},
	computed: {
		globalwindowId() {
			return this.$store.state.globalwindowId
		}
	},
	watch: {
		globalwindowId () {
			if (this.globalwindowId <= 0) {
				return
			}
			if (this.globalwindowIdNow > 0) {
				return
			}
			this.globalwindowInfo = { ...this.globalwindowInfo, ...this.$store.state.globalwindowInfo }
			this.globalwindowIdNow = this.globalwindowInfo.id
			if (this.globalwindowInfo.typelist == 1) {
				this.globalwindowInfo.send_user_avatar = this.serverUrl(this.globalwindowInfo.send_user_avatar)
			}
			setTimeout(() => {
				this.globalWindowPopup = true
				this.$store.commit('setGlobalwindow', null)
			}, 3000)
		}
	},
	methods: {
		handClose(e) {
			this.globalwindowIdNow = 0
			this.intention = 1
			this.globalWindowPopup = false
		},
		async handCheck(e) {
			this.handClose()
			const {data: result} = await this.$http.post('/api/scsj/check_message', {
				id: this.globalwindowInfo.id,
				intention: this.intention
			})
		},
		async handInfo(e) {
			this.handClose()
			return this.$router.push({
				path: `/trial?number=${this.globalwindowInfo.number}`
			})
		},
		async handReport(e) {
			this.handClose()
			const {data: result} = await this.$http.post('/api/scsj/check_message', {
				id: this.globalwindowInfo.id,
				intention: this.intention
			})
			return this.handReportInfo()
		},
		async handReportInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.globalwindowInfo.number
			})
			if (result.code == 1) {
				this.$router.push({
					path: result.data.res.if_cruise == 1 ?
						`/trial-reportinfo-super?number=${this.globalwindowInfo.number}` : 
						`/trial-report?number=${this.globalwindowInfo.number}`
				})
			}
			return
		},
		handConfirm(e) {
			if (e == 1) {
				this.intention = 1
				this.alertDialogTitle = '接受本次邀请'
				this.alertDialogContent = '确定参与本次协助试驾吗？'
			} else {
				this.intention = 0
				this.alertDialogTitle = '谢绝本次邀请'
				this.alertDialogContent = '确定不参与本次协助试驾吗？'
			}
			this.alertDialogShow = true
		},
		async handConfirmReq(e) {
			const {data: result} = await this.$http.post('/api/scsj/check_message', {
				id: this.globalwindowInfo.id,
				intention: this.intention
			})
			if (result.code == 1) {
				if (this.intention == 1) {
					this.globalwindowInfo.typelist = 4
				} else {
					this.handClose()
				}
			} else {
				this.handClose()
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.invite__init{
	padding: 40px 0;
	text-align: center;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #585858;
}
.globalwindow__box{
	width: 600px;
	height: 636px;
	position: relative;
	&__bgleft{
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 334px;
		height: 366px;
		background: linear-gradient(180deg, #E4E4E4 0%, #ECECEC 100%);
	}
	&__bgright{
		position: absolute;
		z-index: -1;
		bottom: 0;
		right: 0;
		width: 494px;
		height: 464px;
		background: linear-gradient(180deg, #E4E4E4 0%, #ECECEC 100%);
	}
}
.xtgz{
	padding: 60px 48px;
	&__avatar{
		text-align: center;
		img{
			width: 128px;
			height: 128px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
	}
	&__name{
		margin-top: 60px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #0C0C0C;
		line-height: 36px;
	}
	&__init{
		margin-top: 12px;
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		color: #0C0C0C;
		line-height: 28px;
	}
	&__car{
		margin-top: 40px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #0C0C0C;
		line-height: 32px;
	}
	&__btns{
		margin-top: 88px;
		display: flex;
		justify-content: space-between;
		div{
			text-align: center;
			width: 240px;
			height: 92px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			line-height: 92px;
		}
		div:first-child{
			background: #fff;
			color: #212121;
		}
		div:last-child{
			background: #FA0037;
			color: #ECECEC;
		}
	}
}
.xtgzok{
	padding: 0 48px;
	&__close{
		position: absolute;
		top: 28px;
		right: 28px;
		display: flex;
		align-items: center;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #818181;
		line-height: 32px;
		img{
			width: 28px;
			height: 28px;
		}
	}
	&__logo{
		padding-top: 120px;
		text-align: center;
		img{
			width: 104px;
			height: 96px;
			border-radius: 50%;
		}
	}
	&__title{
		margin-top: 60px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		color: #0C0C0C;
		line-height: 36px;
	}
	&__btn{
		text-align: center;
		margin-top: 172px;
		width: 100%;
		height: 92px;
		background: #FA0037;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #ECECEC;
		line-height: 92px;
	}
}
.qrtc{
	padding: 60px 48px;
	&__logo{
		text-align: center;
		img{
			width: 104px;
			height: 96px;
			border-radius: 50%;
		}
	}
	&__title{
		margin-top: 40px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		color: #0C0C0C;
		line-height: 36px;
	}
	&__init{
		box-sizing: border-box;
		margin: 66px auto 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 476px;
		height: 92px;
		border: 1px solid #767676;
		padding: 0 40px;
		div{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #212121;
		}
		img{
			width: 40px;
			height: 40px;
		}
	}
	&__btn{
		text-align: center;
		margin-top: 94px;
		width: 100%;
		height: 92px;
		background: #FA0037;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #ECECEC;
		line-height: 92px;
	}
}
</style>