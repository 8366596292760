import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from './utils/request'
import wx from 'weixin-js-sdk'
import './style/font.css'

Vue.use(Vant)
Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$wx = wx
Vue.prototype.onClickLeft = function() {
	router.go(-1)
}

// 图片地址前缀
Vue.prototype.serverUrl = function(url) {
	if(url != undefined ){
		return 'https://cdn2.1des1.com.cn' + url
	}
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
