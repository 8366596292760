import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		globalwindowId: 0,
		globalwindowInfo: null,
		height:0,
	},
	mutations: {
		setGlobalwindow(state, item) {
			console.log(item)
			if (item == null) {
				state.globalwindowId = 0
				state.globalwindowInfo = null
			} else {
				state.globalwindowId = item.id
				state.globalwindowInfo = item
			}
		},
		setheight(state, item){
			state.height = item
		}
	},
	actions: {
	},
	modules: {
	}
})
