import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		component: () => import('../views/login'),
	},
	{
		path: '/layout',
		redirect: '/home',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/layout'),
		children:[
			{
				path: '/home',
				name:'home',
				component: () => import('../views/home'),
				meta:{title:'首页'}
			},
			{
				path: '/center',
				name:'center',
				component: () => import('../views/center'),
				meta:{title:'个人中心'}
			},
		]
	},
	{
		path: '/useredit',
		name:'useredit',
		component: () => import('../views/useredit'),
		meta:{title:'个人信息'}
	},
	{
		path: '/stores-information',
		name:'stores-information',
		component: () => import('../views/stores-information'),
		meta:{title:'门店信息'}
	},
	{
		path: '/test-drive-route',
		name:'test-drive-route',
		component: () => import('../views/test-drive-route'),
		meta:{title:'试驾路线'}
	},
	{
		path: '/test-drive-invitations',
		name:'test-drive-invitations',
		component: () => import('../views/test-drive-invitations'),
		meta:{title:'试驾邀请'}
	},
	{
		path: '/test-drive-type',
		name:'test-drive-type',
		component: () => import('../views/test-drive-type'),
		meta:{title:'试驾车型'}
	},
	{
		path: '/test-drive-agreements',
		name:'test-drive-agreements',
		component: () => import('../views/test-drive-agreements'),
		meta:{title:'试驾协议'}
	},
	{
		path: '/message',
		name:'message',
		component: () => import('../views/message'),
		meta:{title:'消息中心'}
	},
	{
		path: '/reservation',
		name:'reservation',
		component: () => import('../views/reservation'),
		meta:{title:'预约'}
	},
	{
		path: '/trial-add',
		name:'trial-add',
		component: () => import('../views/trial-add'),
		meta:{title:'试驾'}
	},
	{
		path: '/trial-succ',
		name:'trial-succ',
		component: () => import('../views/trial-succ'),
		meta:{title:'试驾创建成功'}
	},
	{
		path: '/trial',
		name:'trial',
		component: () => import('../views/trial'),
		meta:{title:'试驾详情'}
	},
	{
		path: '/trial-edit',
		name:'trial-edit',
		component: () => import('../views/trial-edit'),
		meta:{title:'客户信息'}
	},
	{
		path: '/trial-inviter',
		name:'trial-inviter',
		component: () => import('../views/trial-inviter'),
		meta:{title:'协助邀请'}
	},
	{
		path: '/trial-route',
		name:'trial-route',
		component: () => import('../views/trial-route'),
		meta:{title:'试乘试驾路线'}
	},
	{
		path: '/trial-route-super',
		name:'trial-route-super',
		component: () => import('../views/trial-route-super'),
		meta:{title:'super试乘试驾路线'}
	},
	{
		path: '/trial-see-super',
		name:'trial-see-super',
		component: () => import('../views/trial-see-super'),
		meta:{title:'super试乘试驾'}
	},
	{
		path: '/trial-data-super',
		name:'trial-data-super',
		component: () => import('../views/trial-data-super'),
		meta:{title:'super试乘数据'}
	},
	{
		path: '/trial-report-super',
		name:'trial-report-super',
		component: () => import('../views/trial-report-super'),
		meta:{title:'super试乘试驾报告'}
	},
	{
		path: '/trial-reportinfo-super',
		name:'trial-reportinfo-super',
		component: () => import('../views/trial-reportinfo-super'),
		meta:{title:'super试乘试驾报告'}
	},
	{
		path: '/trial-see',
		name:'trial-see',
		component: () => import('../views/trial-see'),
		meta:{title:'试乘试驾'}
	},
	{
		path: '/trial-data',
		name:'trial-data',
		component: () => import('../views/trial-data'),
		meta:{title:'试乘数据'}
	},
	{
		path: '/trial-report',
		name:'trial-report',
		component: () => import('../views/trial-report'),
		meta:{title:'试乘试驾报告'}
	},
	{
		path: '/trial-agreement',
		name:'trial-agreement',
		component: () => import('../views/trial-agreement'),
		meta:{title:'试乘试驾协议'}
	},
	{
		path: '/map-selsc',
		name:'map-selsc',
		component: () => import('../views/map-selsc'),
		meta:{title:'地图选点'}
	},
	{
		path: '/invitation',
		name:'invitation',
		component: () => import('../views/invitation'),
		meta:{title:'邀请函模板'}
	},
	{
		path: '/invitation/test-drive',
		name:'invitation/test-drive',
		component: () => import('../views/invitation/test-drive'),
		meta:{title:'创建试乘试驾邀请函'}
	},
	{
		path: '/invitation/test-drive-preview',
		name:'invitation/test-drive-preview',
		component: () => import('../views/invitation/test-drive-preview'),
		meta:{title:'邀请函预览'}
	},
	{
		path: '/invitation/letter-info',
		name:'invitation/letter-info',
		component: () => import('../views/invitation/letter-info'),
		meta:{title:'邀请函创建'}
	},
	{
		path: '/invitation/letter-info-preview',
		name:'invitation/letter-info-preview',
		component: () => import('../views/invitation/letter-info-preview'),
		meta:{title:'邀请函预览'}
	},
	{
		path: '/invitation/enjoy-info',
		name:'invitation/enjoy-info',
		component: () => import('../views/invitation/enjoy-info'),
		meta:{title:'恭喜涵创建'}
	},
	{
		path: '/invitation/enjoy-info-preview',
		name:'invitation/enjoy-info-preview',
		component: () => import('../views/invitation/enjoy-info-preview'),
		meta:{title:'恭喜涵预览'}
	},
	{
		path: '/invitation/check-out-preview',
		name:'invitation/check-out-preview',
		component: () => import('../views/invitation/check-out-preview'),
		meta:{title:'离店致谢函预览'}
	},
	{
		path: '/Invitation-list',
		name:'Invitation-list',
		component: () => import('../views/Invitation-list'),
		meta:{title:'邀请函列表'}
	},
	{
		path: '/trial-invitation',
		name:'trial-invitation',
		component: () => import('../views/trial-invitation'),
		meta:{title:'邀请函详情'}
	},
	{
		path: '/trial-invitation/enjoy-village-info',
		name:'trial-invitation/enjoy-village-info',
		component: () => import('../views/trial-invitation/enjoy-village-info'),
		meta:{title:'恭喜涵详情'}
	},
	{
		path: '/trial-invitation/check-outinfo',
		name:'trial-invitation/check-outinfo',
		component: () => import('../views/trial-invitation/check-outinfo'),
		meta:{title:'致谢函详情'}
	},
	{
		path: '/trial-invitation/invitation-info',
		name:'trial-invitation/invitation-info',
		component: () => import('../views/trial-invitation/invitation-info'),
		meta:{title:'邀请函详情'}
	},
	{
		path: '/search',
		name:'search',
		component: () => import('../views/search'),
		meta:{title:'试乘试驾搜索结果'}
	},
	{
		path: '/view-pdf',
		name:'view-pdf',
		component: () => import('../views/view-pdf'),
		meta:{title:'预览pdf'}
	},
	{
		path: '/trial-drive',
		name:'trial-drive',
		component: () => import('../views/trial-drive'),
		meta:{title:'上传驾驶证'}
	},
	{
		path: '/share/test-drive-preview-share',
		name:'test-drive-preview-share',
		component: () => import('../views/share/test-drive-preview-share'),
		meta:{title:'试乘试驾分享确认'}
	},
	{
		path: '/share/letter-info-preview-share',
		name:'letter-info-preview-share',
		component: () => import('../views/share/letter-info-preview-share'),
		meta:{title:'试乘试驾分享确认'}
	},
	{
		path: '/share/enjoy-info-preview-share',
		name:'enjoy-info-preview-share',
		component: () => import('../views/share/enjoy-info-preview-share'),
		meta:{title:'试乘试驾分享确认'}
	},
	{
		path: '/share/check-out-preview-share',
		name:'check-out-preview-share',
		component: () => import('../views/share/check-out-preview-share'),
		meta:{title:'离店致谢函'}
	},
	{
		path: '/share/feedback-share-super',
		name:'feedback-share-super',
		component: () => import('../views/share/feedback-share-super'),
		meta:{title:'super试驾报告分享'}
	},
	{
		path: '/share/drive-report-share-super',
		name:'drive-report-share-super',
		component: () => import('../views/share/drive-report-share-super'),
		meta:{title:'super试驾报告'}
	},
	{
		path: '/share/feedback-share',
		name:'feedback-share',
		component: () => import('../views/share/feedback-share'),
		meta:{title:'试驾报告分享'}
	},
	{
		path: '/share/drive-report-share',
		name:'drive-report-share',
		component: () => import('../views/share/drive-report-share'),
		meta:{title:'试驾报告'}
	},
	{
		path: '/share/booking-share',
		name:'booking-share',
		component: () => import('../views/share/booking-share'),
		meta:{title:'预约分享'}
	},
	{
		path: '/viewimg',
		name:'viewimg',
		component: () => import('../views/viewimg'),
		meta:{title:'查看签名'}
	},
	{
		path: '/scsjsjxq',
		name:'scsjsjxq',
		component: () => import('../views/scsjsjxq'),
		meta:{title:'体验点'}
	},
	{
		path: '/scssjs',
		name:'scssjs',
		component: () => import('../views/scssjs'),
		meta:{title:'签名'}
	},
]

const router = new VueRouter({
  routes
})

export default router
