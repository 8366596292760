import Vue from 'vue'
import axios from './request'
import wx from 'weixin-js-sdk'

// 解析二维码
const getJsonFromUrl = url => {
	var question = url.indexOf("?");
	var hash = url.indexOf("#");
	if (hash == -1 && question == -1) return {};
	if (hash == -1) hash = url.length;
	var query = question == -1 || hash == question + 1 ? url.substring(hash) : url.substring(question + 1, hash);
	var result = {};
	query.split("&").forEach(function (part) {
		if (!part) return;
		part = part.split("+").join(" "); // replace every + with space, regexp-free version
		var eq = part.indexOf("=");
		var key = eq > -1 ? part.substr(0, eq) : part;
		var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
		var from = key.indexOf("[");
		if (from == -1) result[decodeURIComponent(key)] = val;
		else {
			var to = key.indexOf("]", from);
			var index = decodeURIComponent(key.substring(from + 1, to));
			key = decodeURIComponent(key.substring(0, from));
			if (!result[key]) result[key] = [];
			if (!index) result[key].push(val);
			else result[key][index] = val;
		}
	});
	return result;
}

// 时间格式化
export const getDateTime1 = (timestamp) => {
	const date = new Date(timestamp * 1000)
	const Y = date.getFullYear() + '-'
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
	const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	return Y + M + D + h + m + s
}

// 时间格式化
export const getDateTime7 = (timestamp) => {
	const date = new Date(timestamp * 1000)
	const Y = date.getFullYear() + '年'
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日 '
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
	return Y + M + D + h + m
}

// 时间格式化
export const getDateTime2 = (timestamp) => {
	const date = new Date(timestamp * 1000)
	const Y = date.getFullYear() + '年'
	const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日'
	return Y + M + D
}

// 时间格式化
export const getDateTime3 = (timestamp) => {
	const date = new Date(timestamp * 1000)
	const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日 '
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
	return M + D + h + m
}

// 时间格式化
export const getDateTime4 = () => {
	const date = new Date()
	const Y = date.getFullYear() + '-'
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
	return Y + M + D
}

export const getDateTime5 = (datetime) => {
	const time = (new Date(datetime).getTime() + 864000) / 1000
	return time
}

// 时间格式化
export const getDateTime6 = (timestamp1, timestamp2) => {
	const date = new Date(timestamp1 * 1000)
	const Y = date.getFullYear() + '年'
	const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日 '
	const h1 = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	const m1 = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
	if (!timestamp2) {
		return Y + M + D + h1 + m1 
	}
	const date2 = new Date(timestamp2 * 1000)
	const h2 = ' - ' + (date2.getHours() < 10 ? '0' + date2.getHours() : date2.getHours()) + ':'
	const m2 = (date2.getMinutes() < 10 ? '0' + date2.getMinutes() : date2.getMinutes())
	return Y + M + D + h1 + m1 + h2 + m2
}


export const getsignature = async (url) => {
	// alert('获取企微url'+url)
		let {data: res} = await axios.post('/api/wechat/get_qy_wechat_jsapi_signature', {
			store_id: 0,
			url:url,
			name:'凯迪拉克'
		})
		if(res.code == 1){
			res = res.data.res
			wx.config({
				beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.corpid, // 必填，企业微信的corpID
				timestamp: res.timestamp , // 必填，生成签名的时间戳
				nonceStr: res.noncestr , // 必填，生成签名的随机串
				signature: res.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
				jsApiList: [
					'scanQRCode',
					'checkJsApi',
					'hideOptionMenu',
					'onMenuShareWechat',
					'onMenuShareAppMessage',
					'invoke',
					'shareAppMessage',
					'getLocation',
				] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
			})
			wx.ready(function () {
				localStorage.setItem('ticker','1')
				wx.checkJsApi({
						jsApiList: ['chooseImage','scanQRCode','onMenuShareWechat','onMenuShareAppMessage'], // 需要检测的JS接口列表
						success: function(res) {
								// 以键值对的形式返回，可用的api值true，不可用为false
								// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
						}
				})
			})
			wx.error(function (res){
				alert(JSON.stringify(res))
			})
		}
}

// module.exports = {
// 	getJsonFromUrl,
// 	getDateTime1,
// 	getDateTime2,
// 	getDateTime3,
// 	getDateTime4,
// 	getDateTime5,
// 	getDateTime6
// }
